import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './index.scss';
import './theme/theme.scss';
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons

import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import store from './shared/store/store';
import { Provider } from 'react-redux';
import Auth0ProviderWithHistory from './shared/providers/Auth0ProviderWIthHistory';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};
// needed for capacitor plugins to work in a PWA
// import { defineCustomElements } from '@ionic/pwa-elements/loader';

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </IonReactRouter>
      </IonApp>
    </Provider>
  </PostHogProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the app has been rendered the first time
// defineCustomElements(window);
